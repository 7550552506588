import type { JSONSchemaType } from 'ajv';
import type { TAnchor } from '~/typings/types/anchor.types';
import { strictStringSchema } from '~/typings/schemas/string.schemas';

export const strictAnchorSchema: JSONSchemaType<TAnchor> = {
  type: 'object',
  properties: {
    url: strictStringSchema,
    title: strictStringSchema,
  },
  required: ['url', 'title'],
  additionalProperties: false,
};

export const nullableAnchorSchema: JSONSchemaType<TAnchor> = {
  type: 'object',
  nullable: true,
  properties: {
    url: strictStringSchema,
    title: strictStringSchema,
  },
  required: ['url', 'title'],
  additionalProperties: false,
};
